export const initCaptcha = function () {
	const a = Math.ceil(Math.random() * 10);
	const b = Math.ceil(Math.random() * 10);

	const dataCapMin = document.querySelector('[data-cap-min]');
	const dataCapMax = document.querySelector('[data-cap-max]');

	if (dataCapMin) {

		dataCapMin.dataset.capMin = a;
		dataCapMax.dataset.capMax = b;
		const c = a + b

		const numCaptcha = document.querySelector('[data-capcha]');

		numCaptcha.textContent = `Whats is ${a} + ${b}?`;
	}
}
